import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeadline } from '../ui/Card';
import { SimplifiedIBokUser } from '../../hooks/useSearchUsers';
import phoneIcon from './phone.svg';
import emailIcon from './email.svg';
import profileIcon from './profile.svg';

/**
 * Summarized information about a user from the search results.
 * It temporarily contains the button to impersonate a user, but
 * this should probably be moved to the user page.
 * */
const UserSummary: React.FC<{ user: SimplifiedIBokUser }> = ({ user }) => {
  return (
    <Card>
      <div className="flex flex-row space-x-4 align-middle">
        <img src={profileIcon} className="rounded-full h-20" />
        <div className="flex flex-col space-y-1 align-middle">
          <div className="flex flex-row space-x-2 align-middle">
            <CardHeadline>
              <Link to={`/brukere/${user.id}`}>{user.name}</Link>
            </CardHeadline>
          </div>
          <div className="flex flex-row space-x-2 align-middle">
            <img src={emailIcon} className="w-4" />
            <p className="text-base">{user.email ?? 'Eksisterer ikke'}</p>
          </div>
          <div className="flex flex-row space-x-2 align-middle">
            <img src={phoneIcon} className="w-4" />
            <p className="text-base">{user.phone ?? 'Eksisterer ikke'}</p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default UserSummary;
