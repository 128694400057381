/**
 * Determines which host to talk to, based on the application's
 * current environment.
 * */
export const ibokServerHostname = (): string => {
  const { hostname } = window.location;

  if (hostname === 'backdoor.ibok.no') {
    return 'https://api.ibok.no';
  }

  return 'https://api-staging.ibok.no';
};

export const ibokServerUri = (path: string): string => {
  const hostname = ibokServerHostname();
  return `${hostname}/api/v1/${path}`;
};

export const ibokLegacyHostname = (): string => {
  const { hostname } = window.location;

  if (hostname === 'backdoor.ibok.no') {
    return 'https://ibok.no';
  }

  if (hostname === 'ibok-backdoor-staging.smint.no') {
    return 'https://staging.ibok.no/';
  }

  return 'https://ibok-legacy-dev.smint.no';
};
