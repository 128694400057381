import React from 'react';
import { NavLink } from 'react-router-dom';

interface NavItemProps {
  prompt: string;
  link: string;
}

const getStyle = (active: boolean) => {
  return `
        w-full p-2 
        border border-2 border-l-0 border-gray
        hover:border-dark-gray
        inline-block
        ${active ? 'text-white' : 'text-dark-gray'}
        ${active ? 'bg-green' : 'bg-light-gray'}
        ${active ? 'border-green' : ''}
    `;
};

const NavItem: React.FC<NavItemProps> = (props: NavItemProps) => {
  return (
    <NavLink to={props.link} className={({ isActive }) => getStyle(isActive)}>
      <p className="font-bold">{props.prompt}</p>
    </NavLink>
  );
};

export default NavItem;
