import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Book } from '../../hooks/books/useGetBooks';

/**
 * Summarized information about a book from the search results.
 * */
const BookSummary: React.FC<{ book: Book }> = ({ book }) => {
  const metadata = [
    ['Title', book.title],
    ['Author', book.authors?.map((author) => author.name)],
    ['Edition', book.edition],
    ['Published year', book.publishedYear],
  ];

  return (
    <section className="bg-white p-2 px-4 rounded-sm">
      <h3 className="font-bold mb-2">
        <Link to={`/books/${book.isbn}`} className="border-b-2 border-b-green-4 hover:border-b-green-2 hover:text-green">
          {book.title} (isbn: {book.isbn})
        </Link>
      </h3>
      {
        <dl className="grid grid-cols-2 max-w-lg">
          {metadata.map(([label, value]) => (
            <Fragment key={`dl-${label}`}>
              <dt className="bg-green-5 p-1 pl-4 font-bold">{label}</dt>
              <dd className="pl-1">{value}</dd>
            </Fragment>
          ))}
        </dl>
      }
    </section>
  );
};

export default BookSummary;
