import classNames from 'classnames';
import React from 'react';

/**
 * This can be used as a small headline inside a Pill
 * */
export const PillHeadline: React.FC = ({ children }) => {
  return <p className="text-[11px] leading-3">{children}</p>;
};

/**
 * This can be used to present some content inside a Pill.
 * It is given a bold font to stand out.
 * */
export const PillContent: React.FC = ({ children }) => {
  return <strong className="font-bold flex flex-row leading-4 w-fit">{children}</strong>;
};

/**
 * This is a box shaped like a "pill". Nothing more, nothing less
 * */
const Pill: React.FC<{ className?: string }> = ({ className, children }) => {
  const styles = classNames('bg-green-bg flex items-center flex-row justify-center p-1 pl-2 pr-2 rounded-full m-0.5', className);

  return <div className={styles}>{children}</div>;
};

export default Pill;
