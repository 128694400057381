import {
  Form, Formik, Field, ErrorMessage,
} from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../features/ui/Layout';
import UserSummary from '../../features/user/UserSummary';
import useRequireValidAuthentication from '../../hooks/auth/useRequireValidAuthentication';
import useSearchUsers, { SearchQuery, UserSearchField } from '../../hooks/useSearchUsers';

const UserSearchForm: React.FC<{ onSubmit: (query: SearchQuery) => void }> = ({ onSubmit }) => {
  // This is in prioritized order
  const allowedFields: UserSearchField[] = ['email', 'name', 'phone', 'id'];

  return (
    <Formik
      initialValues={{
        field: allowedFields[0],
        query: '',
      }}
      validate={(values) => {
        if (values.field !== 'id' && values.query.trim().length < 2) {
          return {
            query: 'Søkestrengen må ha minst to tegn',
          };
        }
        return {};
      }}
      onSubmit={(values) => onSubmit({
        field: values.field,
        value: values.query,
      })
      }
    >
      {({ isValid, handleBlur, handleChange }) => (
        <Form className="pt-2 pb-2 w-full">
          <div className="flex items-stretch justify-start">
            <select
              name="field"
              id="field"
              defaultValue={allowedFields[0]}
              className="p-1 rounded-tl-md rounded-bl-md hover:cursor-pointer"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {allowedFields.map((field) => (
                <option key={`field-option-${field}`} value={field}>
                  {field}
                </option>
              ))}
            </select>
            <Field type="search" name="query" className="bg-white p-2 flex-1" placeholder="Din søkestreng" />
            <button
              type="submit"
              disabled={!isValid}
              className="bg-green p-2 pl-4 pr-4 text-white rounded-tr-md rounded-br-md
                    hover:bg-green-2 focus:bg-green-2 hover:cursor-pointer
                    disabled:bg-gray disabled:cursor-not-allowed"
            >
              Søk
            </button>
          </div>
          <ErrorMessage name="query" component="p" className="text-danger" />
        </Form>
      )}
    </Formik>
  );
};

const UserSearch: React.FC = () => {
  const { accessToken } = useRequireValidAuthentication();
  const { results, search } = useSearchUsers(accessToken);
  const navigate = useNavigate();

  const runSearch = (query: SearchQuery) => {
    if (query.field === 'id') {
      navigate(`/brukere/${query.value}`);
    } else {
      search(query);
    }
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold m-1">Søk etter brukere</h1>
      <UserSearchForm onSubmit={runSearch} />
      {results?.metadata && (
        <p className="mb-2">
          Fant totalt <strong className="text-bold">{results?.metadata?.total}</strong> brukere
        </p>
      )}
      {results?.results && (
        <div className="grid grid-cols-3 gap-4">
          {results.results.map((user, key) => (
            <UserSummary user={user} key={key} />
          ))}
        </div>
      )}
    </Layout>
  );
};

export default UserSearch;
