import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../features/ui/Layout';
import useAuthentication from '../hooks/auth/useAuthentication';
import useUrlQuery from '../hooks/useUrlQuery';
import { decodeToUserToken } from '../utils/accessTokenUtil';

/**
 * Manages the authentication process, and expects the query parameter `access_token` in the URL.
 * */
const AuthenticationCallback: React.FC = () => {
  const { saveAccessToken } = useAuthentication();
  const query = useUrlQuery();
  const navigate = useNavigate();
  const accessToken = query.get('access_token');

  if (!accessToken) {
    return <Layout>
      <h1>Klarte ikke å autentisere</h1>
      <p>Pass på at alle parametere er med i adressen</p>
    </Layout>;
  }

  const userToken = decodeToUserToken(accessToken);

  useEffect(() => {
    saveAccessToken(accessToken, userToken.exp);
    // Redirect to /brukere until we have a good landing page, when authenticated
    navigate('/brukere');
  }, [accessToken]);

  return <Layout>
    <h1>Autentiserer...</h1>
    <p>Validerer parametere. Du vil automatisk sendes videre når vi er ferdig.</p>
  </Layout>;
};

export default AuthenticationCallback;
