import axios from 'axios';
import { useMutation } from 'react-query';
import { ibokServerUri } from '../utils/api';

export type IBokUserStatuses = 'active' | 'deleted_by_admin' | 'registered';

/**
 * Represents a summarized version of each user (without too many details)
 * */
export interface SimplifiedIBokUser {
  readonly id: number;
  /**
   * Name is mostly non-null for all,
   * but some have still not set their name
   * */
  readonly name?: string;
  readonly email: string;
  readonly status: IBokUserStatuses;
  readonly phone?: string;
  readonly numberOfLogins: number;
  readonly schoolId?: number,
  readonly timeRegistered: string;
  readonly hasVipps: boolean;
}

interface SearchResultMetadata {
  readonly offset: number;
  readonly limit: number;
  readonly total: number
}

export interface SearchResult<T> {
  readonly metadata: SearchResultMetadata;
  readonly results: T[];
}

export type UserSearchField = 'name' | 'email' | 'phone' | 'id';

export interface SearchQuery {
  field: UserSearchField;
  value: string;
}

const searchUsers = (accessToken: string, query: SearchQuery): Promise<SearchResult<SimplifiedIBokUser>> => {
  const uri = ibokServerUri('admin/users/search');

  return axios.post<SearchResult<SimplifiedIBokUser>>(
    uri,
    {
      field: query.field,
      value: query.value.trim(),
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).then((res) => res.data);
};

const useSearchUsers = (accessToken: string) => {
  const { data: results, error, mutate: search } = useMutation<SearchResult<SimplifiedIBokUser>, Error, SearchQuery>(
    ['users', 'search'],
    (query) => searchUsers(accessToken, query),
    {
      onError(err, params) {
        console.error(`Failed to conduct user search: ${JSON.stringify(params)}`, err);
      },
    },
  );

  return { results, error, search };
};

export default useSearchUsers;
