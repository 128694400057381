import { differenceInSeconds } from 'date-fns';
import { useContext } from 'react';
import { AuthenticationContext } from '../../context/authenticationContext';

export const hasTokenExpired = (expiresOn: Date): boolean => {
  const timeNow = new Date();
  return differenceInSeconds(expiresOn, timeNow) < 1;
};

interface UseAuthInformation {
  readonly accessToken?: string;
  readonly expiresOn: Date;
  readonly error?: Error;

  readonly saveAccessToken: (accessToken: string, expiresOn: Date) => void;
  readonly clearAccessToken: () => void;
}

/**
 * Exposes metadata about the authentication state, such as access token.
 * It also provides convenience methods to save and clear the access token
 * (will also clear it from localStorage).
 * */
const useAuthentication = (): UseAuthInformation => {
  const { state, dispatch } = useContext(AuthenticationContext);

  const saveAccessToken = (accessToken: string, expiresOn: Date) => {
    dispatch({
      type: 'STORE_ACCESS_TOKEN',
      accessToken,
      expiresOn,
    });
  };

  const clearAccessToken = () => {
    dispatch({ type: 'CLEAR_ACCESS_TOKEN' });
  };

  return {
    saveAccessToken,
    clearAccessToken,
    accessToken: state.accessToken,
    expiresOn: state.expiresOn,
    error: state.error,
  };
};

export default useAuthentication;
