import axios from 'axios';
import { useMutation } from 'react-query';
import { ibokServerUri } from '../utils/api';
import { SimplifiedIBokUser } from './useSearchUsers';

export type IBokUserStatuses = 'active' | 'deleted_by_admin' | 'registered';

export interface LookupQuery {
  id: string;
}

const getUser = (accessToken: string, query: LookupQuery): Promise<SimplifiedIBokUser> => {
  const uri = ibokServerUri(`admin/users/${query.id}`);

  return axios.get<SimplifiedIBokUser>(
    uri,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).then((res) => res.data);
};

const useGetUser = (accessToken: string) => {
  const { data: results, error, mutate: get } = useMutation<SimplifiedIBokUser, Error, LookupQuery>(
    ['users', 'search'],
    (query) => getUser(accessToken, query),
    {
      onError(err, params) {
        console.error(`Failed to get user: ${JSON.stringify(params)}`, err);
      },
    },
  );

  return { results, error, get };
};

export default useGetUser;
