import React, { useState } from 'react';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { Link } from 'react-router-dom';
import useAuthentication from '../../../hooks/auth/useAuthentication';
import useClock from '../../../hooks/utils/useClock';
import Pill, { PillContent } from '../Pill';
import AuthenticatedUserMetadata from './AuthenticatedUserMetadata';
import timeoutIcon from './timeout.svg';

const HeaderLink: React.FC<{ to: string; children: React.ReactNode }> = ({ to, children }) => {
  return (
    <Link to={to} className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
      {children}
    </Link>
  );
};

const formatTimeDifference = (olderTime: Date, recentTime: Date): string => {
  const differenceMinutes = differenceInMinutes(olderTime, recentTime);

  // For situations where the difference is negative
  if (differenceMinutes < 0) {
    return '< 0 minutter';
  }

  if (differenceMinutes < 2) {
    return `${differenceMinutes} minutt`;
  }
  if (differenceMinutes <= 120) {
    return `${differenceMinutes} minutter`;
  }

  const differenceHours = differenceInHours(olderTime, recentTime);

  if (differenceHours <= 48) {
    return `${differenceHours} timer`;
  }

  const differenceDays = differenceInDays(olderTime, recentTime);

  return `${differenceDays} dager`;
};

/**
 * Give the admin visual aid for how much left she/he has of their login session.
 * */
const AutomaticLogoutTimer: React.FC<{ className?: string }> = ({ className = null }) => {
  const { expiresOn } = useAuthentication();
  const [timeToLogout, setTimeToLogout] = useState(formatTimeDifference(expiresOn, new Date()));

  useClock({
    onTick() {
      setTimeToLogout(formatTimeDifference(expiresOn, new Date()));
    },
    deps: [expiresOn],
  });

  return (
    <Pill className={className || ''}>
      <img src={timeoutIcon} className="mr-2" />
      <PillContent>
        <p>Timeout: {timeToLogout}</p>
      </PillContent>
    </Pill>
  );
};

const Header = () => {
  return (
    <nav className="">
      <div className="w-full">
        <div className="flex items-center justify-end h-16 flex-nowrap">
          <AuthenticatedUserMetadata className="ml-auto" />
          <AutomaticLogoutTimer className="justify-self-end " />
        </div>
        <div className="w-full h-1 rounded-md bg-green" />
      </div>

      <div className="md:hidden" id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <HeaderLink to="/oversikt">Oversikt</HeaderLink>
        </div>
      </div>
    </nav>
  );
};

export default Header;
