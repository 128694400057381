import React from 'react';
import Header from '../Header';
import NavBar from '../Navbar';

const Layout: React.FC = ({ children }) => {
  return (
    <div className="bg-white-smoke h-[100vh] w-[100vw] flex flex-row">
      <NavBar />
      <div className="w-full h-full flex flex-col mx-auto px-4 sm:px-6 lg:px-8">
        <Header />
        <main className="mx-auto w-full bg-light-gray h-full m-4 p-2">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
