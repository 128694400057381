import classNames from 'classnames';
import React from 'react';
import roleIcon from './role.svg';
import useAuthentication from '../../../hooks/auth/useAuthentication';
import { AuthenticatedUserRole, decodeToUserToken, rolesOrderedByPrecedence } from '../../../utils/accessTokenUtil';
import Pill, { PillContent } from '../Pill';

const humanReadableRole = (role: AuthenticatedUserRole): string => {
  const labels: Record<AuthenticatedUserRole, string> = {
    ROLE_DEVELOPER: 'Utvikler',
    ROLE_SUPPORT: 'Brukerstøtte',
    ROLE_USER: 'Vanlig bruker (ikke lov!)',
  };

  return labels[role];
};

const AuthenticatedUserMetadata: React.FC<{ className?: string }> = ({ className = undefined }) => {
  const { accessToken } = useAuthentication();

  if (!accessToken) {
    return null;
  }

  const claims = decodeToUserToken(accessToken);
  const roles = rolesOrderedByPrecedence(claims.roles);
  const mostImportantRole = roles[0];

  const colors = {
    ROLE_DEVELOPER: 'bg-orange-2',
    ROLE_SUPPORT: 'bg-green-2',
    ROLE_USER: 'bg-danger',
  };

  const styles = classNames(colors[mostImportantRole], className);

  return (
    <Pill className={styles}>
      <PillContent>
        <img src={roleIcon} className="mr-2" />
        {humanReadableRole(mostImportantRole)}
      </PillContent>
    </Pill>
  );
};

export default AuthenticatedUserMetadata;
