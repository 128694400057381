import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Formik } from 'formik';
import { IBokUserStatuses, SimplifiedIBokUser } from '../../hooks/useSearchUsers';
import Layout from '../../features/ui/Layout';
import useRequireValidAuthentication from '../../hooks/auth/useRequireValidAuthentication';
import useGetUser from '../../hooks/useGetUsers';
import useUpdateUser from '../../hooks/user/useUpdateUser';
import phoneIcon from './phone.svg';
import emailIcon from './email.svg';
import idIcon from './id.svg';
import profileIcon from './profile.svg';
import Dropdown from '../../features/ui/Components/Dropdown';
import useImpersonateUser from '../../hooks/user/useImpersonateUser';
import ImpersonateUserForm from '../../features/user/ImpersonateUserForm';
import useDeleteUser from '../../hooks/user/useDeleteUser';

const ValidStatuses = {
  active: 'Active',
  deleted_by_admin: 'Deleted by admin',
  registered: 'Registered',
};

interface ParamTypes {
  id: string;
}

interface ErrorTypes {
  [key: string]: string;
}

const UserPage: React.FC = () => {
  const { id } = useParams<keyof ParamTypes>() as ParamTypes;
  const { accessToken } = useRequireValidAuthentication();
  const { results, get } = useGetUser(accessToken);
  const { userUpdateResponse, update } = useUpdateUser(accessToken);
  const { userDeleteResponse, destroy } = useDeleteUser(accessToken);
  const { impersonate } = useImpersonateUser(accessToken);
  const [isImpersonateFormVisible, setIsImpersonateFormVisible] = useState(false);

  useEffect(() => {
    get({ id });
  }, [userUpdateResponse, userDeleteResponse]);

  const onAbort = () => {
    get({ id });
  };

  const onDeleteUser = () => {
    if (results) destroy(results.id);
  };

  const onSubmit = (changedUser: SimplifiedIBokUser) => {
    update(changedUser);
  };

  const impersonateUser = (userId: number, reason: string) => {
    impersonate({ userId, reason });
  };

  if (results) {
    return (
      <Layout>
        <div className="w-full">
          {/* Section for header */}
          <div className="flex flex-row h-40 space-x-4 bg-white p-2 align-middle">
            <img src={profileIcon} className="rounded-full" />
            <div className="flex flex-col h-full space-y-1 justify-center align-middle">
              <div className="flex flex-row space-x-2 align-middle">
                <p className="text-2xl font-bold">{results.name}</p>
              </div>
              <div className="flex flex-row space-x-2 align-middle">
                <img src={idIcon} className="w-4" />
                <p className="text-base">ID: {results.id}</p>
              </div>
              <div className="flex flex-row space-x-2 align-middle">
                <img src={emailIcon} className="w-4" />
                <p className="text-base">{results.email ?? 'Eksisterer ikke'}</p>
              </div>
              <div className="flex flex-row space-x-2 align-middle">
                <img src={phoneIcon} className="w-4" />
                <p className="text-base">{results.phone ?? 'Eksisterer ikke'}</p>
              </div>
            </div>
          </div>

          <Formik
            initialValues={results}
            validate={(values) => {
              const errors: ErrorTypes = {};
              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Please use a valid email format';
              }
              return errors;
            }}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({
              values, setFieldValue, isSubmitting, isValid, handleSubmit,
            }) => (
              <form className="w-full" onSubmit={handleSubmit}>
                <div>
                  <h1 className="text-2xl font-bold p-2">Bruker info</h1>
                  <div className="w-full h-1 rounded-md bg-green mb-4" />
                </div>
                <div className="w-full flex flex-row space-x-56 pl-3">
                  <div className="flex flex-col space-y-3 h-full">
                    <div>
                      <label className="text-lg font-bold">Bruker ID</label>
                      <p className="ml-4">{values.id}</p>
                    </div>
                    <div className="flex flex-col">
                      <label className="text-lg font-bold">Telefon</label>
                      <Field type="phone" name="phone" />
                      <ErrorMessage name="phone" component="div" />
                    </div>
                    <div className="">
                      <label className="text-lg font-bold">Status</label>
                      <Dropdown
                        options={ValidStatuses}
                        value={values.status}
                        onSelect={(value: IBokUserStatuses) => setFieldValue('status', value)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col space-y-3 h-full w-full">
                    <div className="flex flex-col w-1/2">
                      <label className="text-lg font-bold">Navn</label>
                      <Field type="name" name="name" />
                      <ErrorMessage name="name" component="div" />
                    </div>
                    <div className="flex flex-row space-x-3">
                      <div className="flex flex-col w-1/2">
                        <label className="text-lg font-bold">Epost</label>
                        <Field type="email" name="email" />
                        <ErrorMessage className="text-danger" name="email" component="div" />
                      </div>
                    </div>
                    <div className="flex flex-row space-x-3">
                      <div className="flex flex-col w-1/2">
                        <label className="text-lg font-bold">Innloggingsmetode</label>
                        <p className="ml-4">Ikke gitt</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Section for stats */}
                <div className="w-full">
                  <div className="mt-6 mb-4">
                    <h1 className="text-2xl font-bold p-2">Statestikk</h1>
                    <div className="w-full h-1 rounded-md bg-green mb-4" />
                  </div>
                  <div className="w-full flex flex-row space-x-20 pl-3">
                    <div>
                      <p>Konto opprettet</p>
                      <p className="ml-4">{new Date(results.timeRegistered).toLocaleDateString()}</p>
                    </div>
                    <div>
                      <p>Antall aktive annonser</p>
                      <p className="ml-4">Ikke gitt</p>
                    </div>
                    <div>
                      <p>Antall innlogginger</p>
                      <p className="ml-4">{results.numberOfLogins}</p>
                    </div>
                  </div>
                </div>

                {/* Section for buttons */}
                <div className="w-full">
                  <div className="mt-3 mb-3">
                    <div className="w-full h-1 rounded-md bg-green mb-4" />
                  </div>
                  <div className="w-full flex flex-col space-y-6 pl-3">
                    <div className="w-full flex flex-row space-x-3">
                      <button
                        disabled={false}
                        type="reset"
                        className="p-2 pl-4 pr-4 w-fit text-green rounded-md
                              border-4 border-green
                              hover:bg-green-2 focus:bg-green-2 hover:cursor-pointer
                              disabled:bg-gray disabled:cursor-not-allowed"
                        onClick={onAbort}
                      >
                        Avbryt
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting || !isValid}
                        className="bg-green p-2 pl-4 pr-4 w-fit text-white rounded-md
                              hover:bg-opacity-80 focus:bg-opacity-80 hover:cursor-pointer
                              disabled:bg-gray disabled:cursor-not-allowed"
                      >
                        Lagre endringer
                      </button>
                    </div>
                    <button
                      type="button"
                      disabled={isImpersonateFormVisible}
                      className="bg-orange p-2 pl-4 pr-4 w-fit text-white rounded-md
                            hover:bg-orange-2 focus:bg-orange-2 hover:cursor-pointer
                            disabled:bg-gray disabled:cursor-not-allowed"
                      onClick={() => setIsImpersonateFormVisible(true)}
                    >
                      Ta kontroll over bruker
                    </button>
                    {isImpersonateFormVisible && (
                      <ImpersonateUserForm user={results} onSubmit={impersonateUser} onAbort={() => setIsImpersonateFormVisible(false)} />
                    )}
                    <button
                      type="button"
                      disabled={false}
                      className="bg-danger p-2 pl-4 pr-4 w-fit text-white rounded-md
                            hover:bg-opacity-80 focus:bg-opacity-80 hover:cursor-pointer
                            disabled:bg-gray disabled:cursor-not-allowed"
                      onClick={onDeleteUser}
                    >
                      Slett bruker
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Layout>
    );
  }
  return <></>;
};

export default UserPage;
