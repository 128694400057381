import jwtDecode from 'jwt-decode';
import { associate } from './arrayUtil';

/**
 * ROLE_USER is default for "regular" users and grant no access
 * ROLE_DEVELOPER grants potentially more access, and has higher precedence
 * */
const allUserRolesInOrder = ['ROLE_DEVELOPER', 'ROLE_SUPPORT', 'ROLE_USER'] as const;
/**
 * This is a union type of the different roles
 * */
export type AuthenticatedUserRole = typeof allUserRolesInOrder[number];

const isUserRole = (rawRole: string): rawRole is AuthenticatedUserRole => {
  return !!allUserRolesInOrder.find((role) => role === rawRole);
};

/**
 * These are the claims we expect from a user-token
 * generated for backdoor users.
 * */
interface UserTokenClaims {
  readonly aud: string;
  readonly iss: string;
  readonly sub: string;
  readonly email: string;
  readonly roles: AuthenticatedUserRole[];
  readonly exp: Date;
  readonly iat: Date;
}

/**
 * Sorts the list of roles by the precedence of a user role
 * (does not guarantee immutability).
 * Precedence is determined by how much access the role grants
 * */
export const rolesOrderedByPrecedence = (roles: AuthenticatedUserRole[]): AuthenticatedUserRole[] => {
  const priorities = associate(allUserRolesInOrder, (role, index) => {
    // The first item has the highest value
    const priority = allUserRolesInOrder.length - index;
    return [role, priority];
  });

  roles.sort((previous, next) => {
    return priorities[next] - priorities[previous];
  });

  return roles;
};

/**
 * Decodes the JWT to UserTokenClaims without validating the signature
 * */
export const decodeToUserToken = (accessToken: string): UserTokenClaims => {
  const claims = jwtDecode(accessToken) as Record<string, never>;

  // These are provided as Epoch time (seconds)
  // but Date wants them as milliseconds.
  const exp = new Date(claims.exp * 1000);
  const iat = new Date(claims.iat * 1000);

  // Keep only the roles that we know
  const roles = (claims.roles as never[]).filter((rawRole: string) => isUserRole(rawRole));

  return {
    // Standard claims in JWTs
    exp,
    iat,
    iss: claims.iss,
    aud: claims.aud,
    // User specific claims
    sub: claims.sub,
    email: claims.email,
    roles,
  };
};
