import React from 'react';
import ibokLogo from './logo.svg';

const LandingPage: React.FC = () => {
  return (
    <header className="h-screen flex items-center flex-col justify-center">
      <div className="flex justify-center align-center">
        <img src={ibokLogo} alt="iBok Logo" className="h-48" />
      </div>
      <h1 className="text-2xl mt-3 text-white">Bakdør</h1>
      <a
        href="/auth/initiate"
        className="display-block bg-green text-2xl text-white mt-20 font-bold rounded-lg bg-white text-green-600 p-2 pl-10 pr-10 w-fit
                  hover:bg-green-2 focus:bg-green-2"
      >
        Logg inn
      </a>
    </header>
  );
};

export default LandingPage;
