/**
 * Groups items in the array into a Record of single values.
 * Each key and value is determined by associateMapper.
 *
 * If two items have the same key, then the latter value
 * will overwrite the previous one.
 *
 * @param items Collection of items to group
 * @param associateMapper Function that extracts the key and value to store in the record
 * @return Record where each item is stored with an associated key
 * */
export const associate = <IN, KEY extends string, OUT>(
  items: readonly IN[],
  associateMapper: (item: IN, index: number) => [KEY, OUT],
): Record<KEY, OUT> => {
  const grouped: Record<string|number, OUT> = {};

  items.forEach((item, index) => {
    const [key, value] = associateMapper(item, index);
    grouped[key] = value;
  });

  return grouped;
};

/**
 * Groups items in the array into a Record of single values.
 * Each key is determined by the output of keyMapper.
 *
 * If two items have the same key, then the latter value
 * will overwrite the previous one.
 *
 * @param items Collection of items to group
 * @param keyMapper Extracts an item's key used stored in the Record
 * @return Record where each item is stored with an associated key
 * */
export const associateBy = <T, KEY extends string>(items: readonly T[], keyMapper: (item: T) => KEY): Record<KEY, T> => {
  return associate(items, (item) => {
    const key = keyMapper(item);
    return [key, item];
  });
};
