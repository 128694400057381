import { useEffect } from 'react';
import useAuthentication, { hasTokenExpired } from './useAuthentication';

interface ValidAuthentication {
  readonly accessToken: string;
  readonly expiresOn: Date;
}

/**
 * Verifies that the access token is present and haven't expired,
 * before exporting the value. You can use this hook when you need
 * guarantees that the access token is present;
 *
 * Should the access token be missing then the user is signed out.
 * */
const useRequireValidAuthentication = (): ValidAuthentication => {
  const { accessToken, expiresOn, clearAccessToken } = useAuthentication();

  useEffect(() => {
    if (!accessToken || hasTokenExpired(expiresOn)) {
      clearAccessToken();
      // Force clear of any in-memory state, by doing a full redirect to the login page
      window.location.assign('/');
    }
  }, [accessToken, expiresOn.toISOString()]);

  return {
    expiresOn,
    // The TypeScript compiler does not recognize that location.replace
    // will guarantee that accessToken will always be present at this stage
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    accessToken: accessToken!,
  };
};

export default useRequireValidAuthentication;
