import { useFormik } from 'formik';
import React from 'react';
import Layout from '../../features/ui/Layout';
import useSearchBook, { SearchQuery, BookSearchField } from '../../hooks/books/useGetBooks';
import useRequireValidAuthentication from '../../hooks/auth/useRequireValidAuthentication';
import BookSummary from '../../features/book/BookSummary';

const BookFrom: React.FC<{ onSubmit: (query: SearchQuery) => void }> = ({ onSubmit }) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const searchFields: BookSearchField[] = ['TITLE', 'ISBN'];
  const formik = useFormik({
    initialValues: {
      value: '',
      field: searchFields[0],
    },
    onSubmit: (values) => {
      onSubmit({
        field: values.field,
        value: values.value,
      });
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex items-stretch justify-start">
        <select
          name="field"
          id="field"
          className="p-1 rounded-tl-md rounded-bl-md hover:cursor-pointer"
          defaultValue={searchFields[0]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          {searchFields.map((field) => (
            <option key={`field-option-${field}`} value={field}>
              {field}
            </option>
          ))}
        </select>
        <input
          id="value"
          type="text"
          name="value"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="bg-white p-2 flex-1"
          placeholder="Din søkestreng"
        />
        <button
          type="submit"
          name="Submit"
          className="bg-green p-2 pl-4 pr-4 text-white rounded-tr-md rounded-br-md
              hover:bg-green-2 focus:bg-green-2 hover:cursor-pointer
              disabled:bg-gray disabled:cursor-not-allowed"
        >
          Søk
        </button>
      </div>
    </form>
  );
};

const BookSearch: React.FC = () => {
  const { accessToken } = useRequireValidAuthentication();
  const { results, search } = useSearchBook(accessToken);

  const runSearch = (query: SearchQuery) => {
    search(query);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold m-1">Søk etter bøker</h1>
      <BookFrom onSubmit={runSearch} />
      {results && (
        <ul>
          {results.map((book) => (
            <li key={`book-${book.isbn}`} className="mb-2">
              <BookSummary book={book} />
            </li>
          ))}
        </ul>
      )}
    </Layout>
  );
};

export default BookSearch;
