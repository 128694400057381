import React from 'react';
import ibokLogo from './logo.svg';
import NavItem from './NavItem';

const NavBar: React.FC = () => {
  return (
    <nav className="bg-green-bg h-screen w-1/6">
      <div className="w-full h-20 pl-2 flex items-center bg-green">
        <img className="h-12" src={ibokLogo} alt="iBok" />
      </div>
      <NavItem prompt="Feed" link="/oversikt" />
      <NavItem prompt="Bok" link="/books" />
      <NavItem prompt="Bruker" link="/brukere" />
    </nav>
  );
};

export default NavBar;
