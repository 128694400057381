import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import AuthenticationCallback from './pages/AuthenticationCallback';

import LandingPage from './pages/LandingPage';
import OverviewPage from './pages/OverviewPage';
import UserSearch from './pages/UserSearch';
import UserPage from './pages/UserPage';
import BookSearch from './pages/BookSearch';
import BookPage from './pages/BookPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage/>}/>
        <Route path="/callback" element={<AuthenticationCallback/>}/>
        <Route path="/oversikt" element={<OverviewPage/>}/>
        <Route path="/brukere" element={<UserSearch/>}/>
        <Route path="/brukere/:id" element={<UserPage/>}/>
        <Route path="/books" element={<BookSearch/>}/>
        <Route path="/books/:isbn" element={<BookPage/>}/>
      </Routes>
    </div>
  );
}

export default App;
