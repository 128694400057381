import axios from 'axios';
import { useMutation } from 'react-query';
import { ibokLegacyHostname, ibokServerUri } from '../../utils/api';

const retrieveImpersonationToken = (accessToken: string, userId: number, reason: string): Promise<Record<string, string>> => {
  const uri = ibokServerUri('admin/impersonate-user/');

  return axios.post<Record<string, string>>(
    uri,
    {
      reason,
      userIdToImpersonate: userId,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).then((res) => res.data);
};

interface UseImpersonateUserResults {
  impersonate: (body: { userId: number; reason: string }) => void;
}

/**
 * Allow an admin to impersonate a user on ibok.
 * The client is redirected to ibok-legacy when calling impersonate
 * */
const useImpersonateUser = (accessToken: string): UseImpersonateUserResults => {
  const { mutate: impersonate } = useMutation<Record<string, string>, Error, { userId: number; reason: string }>(
    ['users', 'impersonate'],
    ({ userId, reason }) => retrieveImpersonationToken(accessToken, userId, reason),
    {
      onError(err, params) {
        console.error(`Failed to retrieve user impersonation token: ${JSON.stringify(params)}`, err);
      },
      onSuccess(res) {
        const impersonationToken = res.access_token;
        const uri = `${ibokLegacyHostname()}/backdoor/impersonate_user?impersonation_token=${impersonationToken}`;
        window.location.assign(uri);
      },
    },
  );

  return {
    impersonate,
  };
};

export default useImpersonateUser;
