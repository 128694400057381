import axios from 'axios';
import { useMutation } from 'react-query';
import { SimplifiedIBokUser } from '../useSearchUsers';
import { ibokServerUri } from '../../utils/api';

const deleteUser = (accessToken: string, id: number): Promise<SimplifiedIBokUser> => {
  const uri = ibokServerUri(`admin/users/${id}`);

  return axios.delete<SimplifiedIBokUser>(
    uri,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).then((res) => res.data);
};

const useDeleteUser = (accessToken: string) => {
  const { data: userDeleteResponse, error, mutate: destroy } = useMutation<SimplifiedIBokUser, Error, number>(
    ['users', 'search'],
    (user) => deleteUser(accessToken, user),
    {
      onError(err, params) {
        console.error(`Failed to delete user: ${JSON.stringify(params)}`, err);
      },
    },
  );

  return { userDeleteResponse, error, destroy };
};

export default useDeleteUser;
