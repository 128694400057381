import {
  ErrorMessage, Form, Formik, Field,
} from 'formik';
import React from 'react';
import { SimplifiedIBokUser } from '../../hooks/useSearchUsers';

interface ImpersonateUserFormProps {
  user: SimplifiedIBokUser;
  onSubmit: (userId: number, reason: string) => void;
  onAbort: () => void;
}

/**
 * This form ensures that the admin includes
 * a reason to impersonate a user
 * */
const ImpersonateUserForm: React.FC<ImpersonateUserFormProps> = ({ user, onSubmit, onAbort }) => {
  return (
    <Formik
      onSubmit={({ reason }) => onSubmit(user.id, reason)}
      validate={(values) => {
        if (values.reason.length < 5 || values.reason.length > 100) {
          return { reason: 'Begrunnelse må vere mellom 5 og 100 tegn' };
        }
        return {};
      }}
      isInitialValid={() => false}
      initialValues={{
        reason: '',
      }}
    >
      {(props) => (
        <Form className="bg-white-smoke p-2">
          <div className="mb-2">
            <label htmlFor="id-reason" className="block pt-1">
              Forklar kort grunnen til å logge inn som brukeren
            </label>
            <small className="text-[11px] pb-1 block">5 til 100 tegn</small>
            <Field
              as="textarea"
              id="id-reason"
              name="reason"
              className="w-full rounded-sm p-1"
              cols={40}
              rows={2}
              placeholder="Brukeren har mistet annonsene sine / tror personen har svindlet noen"
            >
              {props.values.reason || ''}
            </Field>
            <ErrorMessage name="reason" component="p" className="text-danger" />
          </div>

          <div className="flex justify-start max-w-[15rem]">
            <button
              type="submit"
              disabled={!props.isValid || props.isSubmitting}
              className="p-2 px-5 bg-orange-2 text-white rounded-sm
                    active:bg-orange hover:bg-orange disabled:bg-orange-5
                    disabled:text-gray"
            >
              Logg inn
            </button>
            <button className="ml-2 p-2 px-5 bg-gray rounded-sm" type="reset" onClick={onAbort}>
              Avbryt
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ImpersonateUserForm;
