import React, { useState } from 'react';

interface DropdownArgument {
  [index: string]: string;
}

interface DropdownProps {
  options: DropdownArgument;
  value: string;
  // NB: Fant ikke noen andre løsning på dette problemet, så dette er second best nesten
  // Eller lol d, bli jo basicly best, siden jeg ikke vet bedre
  // eslint-disable-next-line
  onSelect: (value: any) => void;
}

export default function DropdownComponent(props: DropdownProps) {
  const [visible, setVisible] = useState(false);

  const onOptionSelect = (option: string) => {
    props.onSelect(option);
    setVisible(false);
  };

  return (
    <div className="flex relative z-10 h-fit bg-light-gray">
      <button
        id="dropdownDefaultButton"
        className="
                outline outline-1 outline-gray
                bg-white
                focus:ring-4 focus:outline-none
                focus:ring-blue-300 font-medium
                text-sm px-4 py-2.5
                text-center inline-flex items-center"
        type="button"
        onClick={() => setVisible(!visible)}
      >
        {props.options[props.value]}
        <svg
          className="w-4 h-4 ml-2"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      {visible && (
        <div id="dropdown" className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute top-full">
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
            {Object.keys(props.options).map((item) => (
              <li
                key={item}
                className="hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => onOptionSelect(item)}
              >
                <a href="#" className="block px-4 py-2 ">
                  {props.options[item]}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
