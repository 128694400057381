import { formatISO, subHours } from 'date-fns';
import React, { createContext, useReducer } from 'react';

const localStorageKey = 'authenticationState';

interface AuthenticationContextState {
  accessToken?: string;
  expiresOn: Date;
  error?: Error;
}

const initialState: AuthenticationContextState = {
  accessToken: undefined,
  // Always start with an "expired" token
  expiresOn: subHours(new Date(), 1),
  error: undefined,
};

type AuthenticationActions =
  | { type: 'STORE_ACCESS_TOKEN'; accessToken: string; expiresOn: Date }
  | { type: 'CLEAR_ACCESS_TOKEN' };

interface AuthenticationContextProps {
  state: AuthenticationContextState;
  dispatch: (action: AuthenticationActions) => void;
}

// eslint-disable-next-line import/prefer-default-export,@typescript-eslint/naming-convention
export const AuthenticationContext = createContext({
  state: initialState,
} as AuthenticationContextProps);

const persistState = (state: AuthenticationContextState) => {
  const jsonFriendlyState = {
    ...state,
    // We cannot pass the raw date instance to the JSON encode directly
    expiresOn: formatISO(state.expiresOn),
  };
  try {
    localStorage.setItem(localStorageKey, JSON.stringify(jsonFriendlyState));
  } catch (error) {
    console.error('Failed to persist authentication state in localStorage', error);
  }
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AuthenticationActions) => {
  let updatedState = state;

  switch (action.type) {
    case 'STORE_ACCESS_TOKEN':
      updatedState = {
        ...state,
        error: undefined,
        expiresOn: action.expiresOn,
        accessToken: action.accessToken,
      };
      break;
    case 'CLEAR_ACCESS_TOKEN':
      updatedState = {
        ...state,
        error: undefined,
        expiresOn: subHours(new Date(), 1),
        accessToken: undefined,
      };
      break;
    default:
      // If nothing in this context has changed
      // then we return immediately. No point in persisting to localStorage unnecessary
      return state;
  }

  persistState(updatedState);
  return updatedState;
};

/**
 * Loads the initial state from localStorage or the default state (which will force re-authentication).
 * */
const loadInitialState = (): AuthenticationContextState => {
  try {
    const rawState = localStorage.getItem(localStorageKey);
    if (!rawState) {
      return initialState;
    }

    const jsonFriendlyState: Record<string, string> = JSON.parse(rawState);
    return {
      ...jsonFriendlyState,
      expiresOn: new Date(jsonFriendlyState.expiresOn),
    };
  } catch (error) {
    console.error('Failed to load authentication state from localStorage', error);
    // Use the default initial state
    return initialState;
  }
};

export const AuthenticationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, loadInitialState());
  return <AuthenticationContext.Provider value={{ state, dispatch }}>
  {children}
  </AuthenticationContext.Provider>;
};
