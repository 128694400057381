import { useCallback, useEffect } from 'react';

/**
 * Simple hook use to trigger "something" at regular intervals,
 * currently set to each second.
 * */
const useClock = ({ onTick, deps }: { onTick: () => void; deps: unknown[]}) => {
  const handleTick = useCallback(onTick, deps);

  useEffect(() => {
    const interval = setInterval(() => {
      handleTick();
    }, 1000);

    return () => clearInterval(interval);
  }, deps);
};

export default useClock;
