import axios from 'axios';
import { useMutation } from 'react-query';
import { SimplifiedIBokUser } from '../useSearchUsers';
import { ibokServerUri } from '../../utils/api';

const updateUser = (accessToken: string, user: SimplifiedIBokUser): Promise<SimplifiedIBokUser> => {
  const uri = ibokServerUri(`admin/users/${user.id}`);

  return axios.put<SimplifiedIBokUser>(
    uri,
    user,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).then((res) => res.data);
};

const useUpdateUser = (accessToken: string) => {
  const { data: userUpdateResponse, error, mutate: update } = useMutation<SimplifiedIBokUser, Error, SimplifiedIBokUser>(
    ['users', 'search'],
    (user) => updateUser(accessToken, user),
    {
      onError(err, params) {
        console.error(`Failed to update user: ${JSON.stringify(params)}`, err);
      },
    },
  );

  return { userUpdateResponse, error, update };
};

export default useUpdateUser;
