import React from 'react';
import Layout from '../../features/ui/Layout';

const OverviewPage: React.FC = () => {
  // This is copied from https://tailwindui.com/components/application-ui/application-shells/stacked
  // Not sure if we want to use it, or parts of it
  return <Layout>
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="px-4 py-6 sm:px-0">
        <div className="border-4 border-dashed border-gray-200 rounded-lg h-96"/>
      </div>
    </div>
  </Layout>;
};

export default OverviewPage;
