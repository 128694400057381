import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import useSearchBooks, { Book } from '../../hooks/books/useGetBooks';
import Layout from '../../features/ui/Layout';
import useRequireValidAuthentication from '../../hooks/auth/useRequireValidAuthentication';

const MINIMUM_LENGTH = 2;

type bookError = {
  title?: string;
  isbn?: string;
  authors?: string;
  publisher?: string;
  binding?: string;
  edition?: string;
  tags?: string;
  publishedYear?: string;
};
interface ParamTypes {
  isbn: string;
}

const isInvalidLength = (value?: string): boolean => {
  if (!value) {
    return false;
  }
  if (value.length < MINIMUM_LENGTH) {
    return true;
  }
  return false;
};

const validate = (values: Book): bookError => {
  const errors: bookError = {};
  if (isInvalidLength(values.authors?.toString())) {
    errors.authors = 'Må være mer enn 2 bokstaver.';
  }
  if (values.isbn.length !== 13) {
    errors.isbn = 'Må være 13 bokstaver.';
  }
  if (isInvalidLength(values.publisher)) {
    errors.publisher = 'Må være mer enn 2 bokstaver.';
  }
  if (isInvalidLength(values.binding)) {
    errors.binding = 'Må være mer enn 2 bokstaver.';
  }
  if (isInvalidLength(values.edition?.toString())) {
    errors.edition = 'Må være mer enn 2 bokstaver.';
  }
  if (isInvalidLength(values.imageUrl?.toString())) {
    errors.edition = 'Må være mer enn 2 bokstaver.';
  }
  if (isInvalidLength(values.publishedYear?.toString())) {
    errors.edition = 'Må være mer enn 2 bokstaver.';
  }

  return errors;
};

const BookPageForm: React.FC = () => {
  const { isbn } = useParams<keyof ParamTypes>() as ParamTypes;
  const { accessToken } = useRequireValidAuthentication();
  const { results: books, search } = useSearchBooks(accessToken);
  const [initialValues, setInitialValues] = useState<Book>({
    title: '',
    isbn: '',
  });

  useEffect(() => {
    search({ field: 'ISBN', value: isbn });
  }, []);

  useEffect(() => {
    if (books) {
      setInitialValues(books[0]);
    }
  }, [books]);

  const formik = useFormik({
    initialValues,
    validate,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: (values: Book) => {
      // API call for å lagre bok i server
      // setBook({ ...book, ...values },);
      // console.log("submitting", values)
    },
    enableReinitialize: true,
  });

  if (!books) {
    return <form>no results</form>;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>Title: {books[0].title}</div>
      <input name="title" type="text" value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} />
      <div>ISBN: {books[0].isbn}</div>
      <input name="isbn" type="text" value={formik.values.isbn} onChange={formik.handleChange} onBlur={formik.handleBlur} />
      <div>Authors: {books[0].authors?.map((author) => `${author.name},`)}</div>
      <input
        name="authors"
        type="text"
        value={formik.values.authors?.map((auther) => auther.name)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <div>Publisher: {books[0].publisher}</div>
      <input name="publisher" type="text" value={formik.values.publisher ? formik.values.publisher : ''} onChange={formik.handleChange} />
      <div>Binding: {books[0].binding}</div>
      <input name="binding" type="text" value={formik.values.binding ? formik.values.binding : ''} onChange={formik.handleChange} />
      <div>Edition: {books[0].edition}</div>
      <input name="edition" type="text" value={formik.values.edition ? formik.values.edition : ''} onChange={formik.handleChange} />
      <div>Published Year: {books[0].publishedYear}</div>
      <input
        name="publishedYear"
        type="text"
        value={formik.values.publishedYear ? formik.values.publishedYear : ''}
        onChange={formik.handleChange}
      />
      <div>imageUrl: {books[0].imageUrl}</div>
      <input type="text" value={formik.values.imageUrl ? formik.values.imageUrl : ''} onChange={formik.handleChange} />
      <button
        type="submit"
        className="bg-green p-2 pl-4 pr-4 text-white rounded-tr-md rounded-br-md
                  hover:bg-green-2 focus:bg-green-2 hover:cursor-pointer
                  disabled:bg-gray disabled:cursor-not-allowed"
      >
        change
      </button>
    </form>
  );
};

const BookPage: React.FC = () => {
  return (
    <Layout>
      <h1 className="text-2xl font-bold m-1">Søk etter bøker</h1>
      <BookPageForm />
    </Layout>
  );
};

export default BookPage;
