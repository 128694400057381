import axios from 'axios';
import { useMutation } from 'react-query';
import { ibokServerUri } from '../../utils/api';

export type Author = {
  id: number
  name: string
}
/**
 * Represents a summarized version of each book (without too many details)
 * */
export type Book = {
  readonly title: string;
  readonly isbn: string;
  readonly authors?: Author[];
  readonly publisher?: string;
  readonly binding?: string;
  readonly edition?: number;
  readonly imageUrl?: string;
  readonly publishedYear?: number;
}

export interface SearchResult<T> {
  readonly results: T[];
}

export type BookSearchField = 'TITLE' | 'ISBN';
export interface SearchQuery {
  field: BookSearchField;
  value: string;
}

const searchBooks = (accessToken: string, query: SearchQuery): Promise<Book[]> => {
  const uri = ibokServerUri(`admin/books/search?field=${query.field}&value=${query.value.trim()}`);

  return axios.get<Book[]>(
    uri,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).then((res) => {
    return res.data;
  });
};

const useSearchBook = (accessToken: string) => {
  const { data: results, error, mutate: search } = useMutation<Book[], Error, SearchQuery>(
    ['books', 'search'],
    (query) => searchBooks(accessToken, query),
    {
      onError(err, params) {
        console.error(`Failed to conduct user search: ${JSON.stringify(params)}`, err);
      },
    },
  );

  return { results, error, search };
};

export default useSearchBook;
