import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { AuthenticationProvider } from './context/authenticationContext';
import { queryClient } from './queryClient';

// We know that root exists. Everything would fail otherwise
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <App/>
        </AuthenticationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
